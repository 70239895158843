@import url("https://fonts.googleapis.com/css2?family=Public+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css?family=Material+Icons|Material+Icons+Outlined|Material+Icons+Two+Tone|Material+Icons+Round|Material+Icons+Sharp");
@import url("https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@24,400,0,0");

body {
  margin: 0;
  font-family: "Public Sans", "Poppins", sans-serif, -apple-system,
    BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.text-primary {
  color: rgb(0, 167, 111) !important;
}
body a.text-primary:hover,
body a.text-primary:focus {
  color: rgb(0, 167, 111) !important;
}
.text-primary {
  color: rgb(0, 167, 111) !important;
}
.btn-primary {
  background-color: rgb(0, 167, 111) !important;
  border-color: rgb(0, 167, 111) !important;
}
.btn-primary:hover {
  background-color: rgb(0, 120, 103) !important;
  border-color: rgb(0, 120, 103) !important;
}
.text-justify {
  text-align: justify !important;
}

header {
  background: #fff;
  box-shadow: 0 0 10px 0 rgb(0 0 0/20%);
  z-index: 999999;
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
}
footer {
  position: relative;
  background: #fff;
}
footer .footer-bg {
  background: linear-gradient(135deg, rgb(0, 167, 111), rgb(0, 120, 103));
}
.footer-logo {
  filter: brightness(0) invert(1);
  height: 32px;
  opacity: 0.9;
}
.abdmnha-logo-1 {
  height: 75px;
  margin-right: 2px;
}
.abdmnha-logo-2 {
  height: 50px;
  margin-left: 50px;
}
footer .footer-c > div {
  border-left: solid 1px rgba(256, 256, 256, 0.2);
  border-right: solid 1px rgba(256, 256, 256, 0.2);
}
footer .footer-desc {
  opacity: 0.7;
}
footer .copyrights {
  background: linear-gradient(135deg, rgb(0, 167, 111), rgb(0, 120, 103));
}
footer .copyrights * {
  opacity: 0.8;
}
footer .footer-email {
  display: flex;
  align-items: center;
}
footer .footer-email a {
  text-decoration: none;
  color: #fff;
  font-size: 1.2rem;
  opacity: 0.8;
}
footer .footer-email a:hover {
  text-decoration: underline;
  opacity: 1;
}
footer .footer-call {
  display: flex;
  text-decoration: none;
  color: #fff;
  font-size: 1.1rem;
  align-items: center;
  opacity: 0.8;
}
footer .material-symbols-outlined {
  height: 42px;
  width: 42px;
  background: rgba(0, 167, 111);
  border-radius: 50%;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  line-height: 24px;
  margin-right: 16px;
  border: solid 2px rgba(256, 256, 256, 0.3);
}
footer .navbar > ul > li > a {
  padding: 0px 0px 0px 16px;
  color: #fff;
  margin-bottom: 24px;
  opacity: 0.8;
}
footer .navbar > ul > li > a:hover {
  opacity: 1;
  color: #fff;
}
footer .navbar > ul > li > a::before {
  content: "";
  position: absolute;
  width: 6px;
  height: 6px;
  background: rgba(256, 256, 256, 0.6);
  left: 0px;
  top: 10px;
  border-radius: 50%;
}
.footer-down-btns .btn {
  margin-right: 16px;
}
.footer-down-btns .btn img {
  max-height: 42px;
}
footer .sm-icons img {
  height: 42px;
  margin-right: 24px;
}

.navbar ul li {
  position: relative;
}
.navbar ul li a {
  position: relative;
  text-decoration: none;
  padding: 12px 24px;
  display: block;
  color: #333;
}
.navbar > ul > li > a {
  font-weight: 500;
}
.navbar ul li a:hover {
  color: rgb(0, 167, 111);
}
.navbar ul li ul {
  display: none;
  background: rgb(18, 70, 128);
  z-index: 1;
  position: absolute;
  left: 24px;
  top: 48px;
  min-width: 248px;
  border-radius: 4px;
  padding: 8px 0px 4px 0px;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.4);
}
.navbar ul li ul::before {
  position: absolute;
  top: -6px;
  left: 20px;
  content: "";
  width: 0px;
  height: 0px;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid rgb(18, 70, 128);
  font-size: 0px;
  line-height: 0px;
}
.navbar ul li:hover ul {
  display: inherit;
}
.navbar ul li ul li a {
  color: #fff;
  padding: 8px 12px;
  display: block;
  border-bottom: solid 1px rgba(256, 256, 256, 0.3);
}
.navbar ul li ul li a:hover {
  background: #fc7f0c;
  color: #fff;
}
.navbar ul li ul li:last-child a {
  border-bottom: 0px none;
}

.shadow1 {
  box-shadow: rgba(145, 158, 171, 0.2) 0px 0px 2px 0px,
    rgba(145, 158, 171, 0.12) 0px 12px 24px -4px;
  border-radius: 16px;
}
.home h2 {
  font-size: 3.4rem;
  line-height: 5.2rem;
  font-weight: 600;
  position: relative;
  padding-bottom: 4px;
  margin-bottom: 24px;
  background: -webkit-linear-gradient(
      300deg,
      rgb(0, 167, 111) 0%,
      rgb(255, 171, 0) 100%
    )
    padding-box;
  -webkit-background-clip: text;
  font-weight: bolder;
  -webkit-text-fill-color: transparent;
}
.home h2::after {
  content: "";
  width: 100px;
  height: 2px;
  background: -webkit-linear-gradient(
    300deg,
    rgb(0, 167, 111) 0%,
    rgb(255, 171, 0) 100%
  );
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 0px;
  margin: auto;
}
.home h5 {
  font-size: 1rem;
  font-weight: 400;
}

.home-features {
  background-image: linear-gradient(
      rgba(255, 255, 255, 0.9),
      rgba(255, 255, 255, 0.9)
    ),
    url("../images/overlay_2.jpg");
  padding: 72px 0px;
}
.home-features h2 {
  color: rgb(0, 167, 111);
}
.home-features h2::after {
  right: auto;
}

.home-features .features-icon {
  font-size: 64px;
  color: rgb(0, 167, 111);
}
.home-features .feature-box {
  cursor: pointer;
  box-shadow: rgba(145, 158, 171, 0.2) 0px 7px 8px -4px,
    rgba(145, 158, 171, 0.14) 0px 13px 19px 2px,
    rgba(145, 158, 171, 0.12) 0px 5px 24px 4px;
  border-radius: 8px;
  display: flex;
  min-height: 202px;
  align-items: center;
  background: #fff;
}
.home-features .feature-box .features-icon-c1 {
  color: #00a76f;
}
.home-features .feature-box .features-icon-c2 {
  color: #ff9933;
}
.home-features .feature-box .features-icon-c3 {
  color: #346e9c;
}
.home-features .feature-box h5 {
  color: rgb(33, 43, 54);
  margin: 12px 0px 0px 0px;
}
.home-features .feature-box:hover {
  background: rgb(0, 167, 111);
  transition: background-color 1s;
}
.home-features .feature-box:hover h5 {
  color: #fff !important;
  transition: color 0.5s;
}
.home-features .feature-box:hover span {
  color: #fff !important;
  transition: color 0.5s;
}
.home-features .feature-box:hover img {
  filter: brightness(0) invert();
  transition: filter 0.5s;
}
.home-features .active {
  background: rgb(0, 167, 111);
}
.home-features .active h5 {
  color: #fff !important;
}
.home-features .active span {
  color: #fff !important;
}
.home-features .active img {
  filter: brightness(0) invert();
}
.home-features-content {
  padding-left: 48px;
}
.home-features-content h3 {
  color: rgb(33, 43, 54);
  font-weight: 700;
}
.home-features-content ul {
  min-height: 348px;
}
.home-features-content ul li {
  position: relative;
  margin-bottom: 16px;
  font-size: 1.2rem;
  padding-left: 48px;
}
.home-features-content ul li .check-bullet {
  font-size: 24px;
  font-weight: normal;
  position: absolute;
  left: 8px;
  top: 2px;
  color: rgb(0, 167, 111);
}
.home-features-content .btn {
  display: flex;
  font-size: 0.9rem;
  line-height: 28px;
  align-items: center;
  border: solid 1px rgba(0, 0, 0, 0.2);
  background: #fff;
  justify-content: center;
  max-width: 134px;
  align-items: center;
}
.home-features-content .btn:hover {
  background: rgb(0, 167, 111);
  border: solid 1px rgb(0, 167, 111);
}

.home-benefits {
  background-image: linear-gradient(
      rgba(255, 255, 255, 0.9),
      rgba(255, 255, 255, 0.9)
    ),
    url("../images/overlay_2.jpg");
  padding: 48px 0px 72px 0px;
}
.home-benefits h5 {
  font-size: 1.4rem;
  font-weight: 700;
  color: rgb(33, 43, 54);
  margin-top: -4px;
  text-align: center;
}
.home-benefits span {
  /* color: rgb(0, 167, 111); */
  font-size: 42px;
  width: 72px;
  height: 72px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: rgba(145, 158, 171, 0.2) 0px 6px 7px -4px,
    rgba(145, 158, 171, 0.14) 0px 11px 15px 1px,
    rgba(145, 158, 171, 0.12) 0px 4px 20px 3px;
  background: rgb(0, 167, 111);
  color: #fff;
  position: relative;
}

.home-benefits .col-left li:nth-child(1) span,
.home-benefits .col-left li:nth-child(1) span::after {
  background: rgb(0, 167, 111);
}
.home-benefits .col-left li:nth-child(2) span,
.home-benefits .col-left li:nth-child(2) span::after {
  background: #346e9c;
}
.home-benefits .col-left li:nth-child(3) span,
.home-benefits .col-left li:nth-child(3) span::after {
  background: #ff9933;
}
.home-benefits .col-left li:nth-child(4) span,
.home-benefits .col-left li:nth-child(4) span::after {
  background: rgb(221, 95, 101);
}

.home-benefits .col-right li:nth-child(3) span,
.home-benefits .col-right li:nth-child(4) span::after {
  background: rgb(0, 167, 111);
}
.home-benefits .col-right li:nth-child(3) span,
.home-benefits .col-right li:nth-child(3) span::after {
  background: #346e9c;
}
.home-benefits .col-right li:nth-child(2) span,
.home-benefits .col-right li:nth-child(2) span::after {
  background: #ff9933;
}
.home-benefits .col-right li:nth-child(1) span,
.home-benefits .col-right li:nth-child(1) span::after {
  background: rgb(221, 95, 101);
}

.home-benefits span::after {
  content: "";
  position: absolute;
  right: -100%;
  top: 50%;
  width: 300%;
  height: 2px;
  background: rgb(0, 167, 111);
  z-index: -1;
}
.home-benefits .col-right span::after {
  right: auto;
  left: -100%;
}
.home-benefits h4 {
  margin: 0px;
  font-size: 1rem;
  text-align: right;
  font-weight: 700;
  background: #fff;
  border-radius: 8px;
  padding: 12px 8px;
  box-shadow: rgba(145, 158, 171, 0.2) 0px 6px 7px -4px,
    rgba(145, 158, 171, 0.14) 0px 11px 15px 1px,
    rgba(145, 158, 171, 0.12) 0px 4px 20px 3px;
}
.home-benefits ul {
  flex-wrap: wrap;
  height: 100%;
  position: relative;
  z-index: 1;
}
.home-benefits img {
  border-radius: 8px;
  box-shadow: rgba(145, 158, 171, 0.2) 0px 6px 7px -4px,
    rgba(145, 158, 171, 0.14) 0px 11px 15px 1px,
    rgba(145, 158, 171, 0.12) 0px 4px 20px 3px;
  position: relative;
  z-index: 2;
}

.home-howitworks {
  background: rgba(0, 167, 111, 0.05);
  background-image: linear-gradient(
      rgba(255, 255, 255, 0.9),
      rgba(255, 255, 255, 0.9)
    ),
    url("../images/overlay_4.jpg");
  padding: 48px;
}
.home-howitworks h3 {
  margin-top: 36px;
  font-size: 1.5rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-weight: 600;
  color: rgb(33, 43, 54);
}
.home-howitworks h5 {
  font-size: 1.4rem;
  font-weight: 700;
  color: rgb(33, 43, 54);
  margin-top: -4px;
}
.home-howitworks .mobile-screen {
  /* border-radius: 8px;
  box-shadow: rgba(145, 158, 171, 0.2) 0px 6px 7px -4px,
    rgba(145, 158, 171, 0.14) 0px 11px 15px 1px,
    rgba(145, 158, 171, 0.12) 0px 4px 20px 3px; */
  /* width: 84%; */
}
.home-howitworks h3.active {
  color: rgb(0, 167, 111);
}
.home-howitworks h3 img {
  margin-right: 16px;
}
.home-howitworks h3:hover img {
  -webkit-transform: rotate(1turn);
  transform: rotate(1turn);
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.home-howitworks h4 {
  margin-top: 24px;
  font-size: 1.1rem;
  padding-left: 42px;
  color: #333;
}
.home-howitworks ul {
  list-style: none;
  padding-left: 42px;
}
.home-howitworks ul li {
  font-size: 1.1rem;
  position: relative;
  padding-left: 36px;
  margin-bottom: 12px;
  color: rgb(99, 115, 129);
}
.home-howitworks ul li::before {
  content: "";
  position: absolute;
  background: url(../images/check-bullet-round.svg) left top no-repeat;
  left: 0px;
  top: 4px;
  width: 20px;
  height: 20px;
}

.home-whoweare {
  background: linear-gradient(135deg, rgb(0, 167, 111), rgb(0, 120, 103));
  padding: 62px 0px 38px 0px;
}
.home-whoweare .letter-box {
  border: solid 1px rgba(256, 256, 256, 0.3);
  min-height: 100%;
}
.home-whoweare .letter-box:hover {
  background: rgba(0, 0, 0, 0.2);
  transition: background-color 0.5s;
}
.home-whoweare .letter {
  font-size: 150px;
  font-weight: bold;
  line-height: 150px;
  color: transparent;
  -webkit-text-stroke: 1px rgb(255, 255, 255);
  filter: drop-shadow(6px 0 0 rgba(256, 256, 256, 0.5));
}
.home-whoweare h3 {
  font-size: 22px;
  font-weight: bold;
}
.home-whoweare p {
  opacity: 0.5;
  font-size: 0.9rem;
  margin-bottom: 0px;
}

.home-slider {
  height: calc(100vh);
  width: 100%;
  background-color: rgba(0, 0, 0, 0);
  background: linear-gradient(
      rgba(255, 255, 255, 0.9),
      rgba(255, 255, 255, 0.9)
    ),
    url("../images//overlay_3.jpg");
  background-size: cover;
  background-attachment: scroll, scroll;
  background-position: center center;
  background-clip: border-box, border-box;
  background-origin: padding-box, padding-box;
  background-repeat: no-repeat;
  position: relative;
}
.home-slider-height {
  height: calc(100vh);
}
.home-slider-content {
  text-align: center;
  min-height: 312px;
}
.home-slider h3 {
  font-size: 2rem;
  line-height: 1.3333333;
  font-weight: 800;
  color: rgb(33, 43, 54);
  max-width: 75%;
  margin: 0px auto;
}
.home-slider h3 span {
  font-weight: bolder;
}
.home-slider h2 {
  font-size: 3.4rem;
  background: -webkit-linear-gradient(
      300deg,
      rgb(0, 167, 111) 0%,
      rgb(255, 171, 0) 25%,
      rgb(0, 167, 111) 50%,
      rgb(255, 171, 0) 75%,
      rgb(0, 167, 111) 100%
    )
    0% 0% / 400% padding-box;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  padding: 0px;
  margin: 0px;
  font-weight: 800;
  line-height: 4.6rem;
}
.home-slider h2::after {
  display: none;
}

.sliding-background-1 {
  background: url(../images/app-screens1.png) repeat-y;
  -webkit-animation: bgScroll1 10000s linear infinite;
  animation: bgScroll1 10000s linear infinite;
  height: 100%;
  background-size: cover;
  position: relative;
  top: -16px;
  width: 344px;
}
.sliding-background-2 {
  background: url(../images/app-screens2.png) repeat-y;
  -webkit-animation: bgScroll2 20000s linear infinite;
  animation: bgScroll2 20000s linear infinite;
  height: 100%;
  background-size: cover;
  position: absolute;
  left: 0px;
  top: -48px;
  width: 720px;
}
.banner-curve-l {
  backdrop-filter: blur(6px);
  background-color: rgb(255, 255, 255);
  z-index: 9;
  bottom: 0px;
  height: 80px;
  width: 50%;
  position: absolute;
  clip-path: polygon(0% 0%, 100% 100%, 0% 100%);
  left: 0px;
}
.banner-curve-r1 {
  backdrop-filter: blur(6px);
  background-color: rgba(255, 255, 255, 0.48);
  z-index: 9;
  bottom: 0px;
  height: 80px;
  width: 50%;
  position: absolute;
  clip-path: polygon(0% 0%, 100% 100%, 0% 100%);
  right: 0px;
  transform: scaleX(-1);
}
.banner-curve-r2 {
  backdrop-filter: blur(6px);
  background-color: rgba(255, 255, 255, 0.48);
  bottom: 0px;
  width: 50%;
  position: absolute;
  clip-path: polygon(0% 0%, 100% 100%, 0% 100%);
  right: 0px;
  transform: scaleX(-1);
  height: 48px;
  z-index: 10;
}
.banner-curve-r3 {
  backdrop-filter: blur(6px);
  background-color: rgb(255, 255, 255);
  bottom: 0px;
  width: 50%;
  position: absolute;
  clip-path: polygon(0% 0%, 100% 100%, 0% 100%);
  right: 0px;
  transform: scaleX(-1);
  z-index: 11;
  height: 24px;
}
.home-blogs {
  background-image: linear-gradient(
      rgba(255, 255, 255, 0.9),
      rgba(255, 255, 255, 0.9)
    ),
    url("../images/overlay_2.jpg");
  padding: 48px 0px;
}
.home-blogs h5 {
  font-size: 1.4rem;
  font-weight: 700;
  color: rgb(33, 43, 54);
  margin-top: -4px;
  text-align: center;
}
.home-blogs h4 {
  font-size: 1.1rem;
  line-height: 1.8rem;
  color: rgb(33, 37, 41);
}
.home-blogs a {
  text-decoration: none;
}
.home-blogs .shadow1 {
  min-height: 100%;
  background: #fff;
}
.home-blogs .shadow1:hover {
  background: rgb(0, 167, 111);
  transition: background-color 1s;
  cursor: pointer;
}
.home-blogs .shadow1:hover h4,
.home-blogs .shadow1:hover p {
  color: #fff !important;
}
.home-blogs img {
  border-radius: 16px 16px 0px 0px;
}
.home-testimonials {
  background-image: linear-gradient(
      rgba(255, 255, 255, 0.9),
      rgba(255, 255, 255, 0.9)
    ),
    url("../images/overlay_2.jpg");
  padding: 48px 0px;
}

.home-testimonials-wrap {
  text-align: center;
  padding: 4px 16px;
  min-height: 360px;
}
.swiper-button-next::after,
.swiper-button-prev::after {
  font-size: 12px;
  color: red;
}
.swiper-button-next::after,
.swiper-button-prev::after {
  font-size: 28px;
  color: rgb(0, 167, 111);
}
.swiper-button-prev,
.swiper-button-next {
  top: 110px;
}
.swiper-button-next {
  right: -4px;
}
.swiper-button-prev {
  left: -4px;
}
.swiper-pagination-bullet-active {
  background: rgb(0, 167, 111);
}
.home-testimonials .home-testimonials-box {
  position: relative;
  border: solid 1px rgb(0, 167, 111);
  background: #fff;
  box-shadow: rgba(145, 158, 171, 0.2) 0px 7px 8px -4px,
    rgba(145, 158, 171, 0.14) 0px 13px 19px 2px,
    rgba(145, 158, 171, 0.12) 0px 5px 24px 4px;
  border-radius: 8px;
  padding: 24px 24px 24px 24px;
  min-height: 212px;
}

.home-testimonials .home-testimonials-box::before {
  content: "";
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid rgb(0, 167, 111);
  font-size: 0;
  height: 0;
  left: 50%;
  line-height: 0px;
  position: absolute;
  bottom: -10px;
  width: 0;
}
.home-testimonials .home-testimonials-box::after {
  content: "\201c";
  font-size: 82px;
  left: 16px;
  line-height: 0px;
  position: absolute;
  top: 48px;
  color: rgba(0, 167, 111, 1);
  display: none;
}
.home-testimonials h5 {
  font-size: 1.4rem;
  font-weight: 700;
  color: rgb(33, 43, 54);
  margin-top: -4px;
  text-align: center;
}
.home-testimonials .swiper-container {
  width: 1320px;
  overflow: hidden;
}

.home-vblogs {
  background-image: linear-gradient(
      rgba(255, 255, 255, 0.9),
      rgba(255, 255, 255, 0.9)
    ),
    url("../images/overlay_2.jpg");
  padding: 48px 0px;
}
.home-vblogs-wrap {
  text-align: center;
  padding: 4px 16px;
  min-height: 320px;
}
.home-vblogs .home-vblogs-box {
  position: relative;
  background: #fff;
  box-shadow: rgba(145, 158, 171, 0.2) 0px 7px 8px -4px,
    rgba(145, 158, 171, 0.14) 0px 13px 19px 2px,
    rgba(145, 158, 171, 0.12) 0px 5px 24px 4px;
  border-radius: 8px;
  padding: 12px 12px 4px 12px;
}
.home-vblogs .home-vblogs-box iframe {
  border-radius: 12px;
}
.home-vblogs h5 {
  font-size: 1.4rem;
  font-weight: 700;
  color: rgb(33, 43, 54);
  margin-top: -4px;
  text-align: center;
}
.home-vblogs .swiper-container {
  width: 1320px;
  overflow: hidden;
}

.page-inside {
  min-height: 100vh;
  margin-bottom: 62px;
}
.page-inside-banner {
  background: linear-gradient(
    135deg,
    rgba(0, 167, 111, 0.7),
    rgba(0, 120, 103, 0.7)
  );
  margin-bottom: 62px;
  margin-top: 80px;
  position: relative;
  min-height: 280px;
  display: flex;
  align-items: center;
}
.page-inside-banner img {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}
.page-inside h1 {
  font-size: 3.4rem;
  margin: 0px;
  color: #fff;
  font-weight: 700;
  text-shadow: 3px 3px 10px rgb(0, 70, 47);
}
.page-inside p {
  font-size: 1.2rem;
  line-height: 2rem;
  text-align: justify;
}
.page-inside b {
  font-weight: 700;
  color: rgba(33, 43, 54, 0.8);
}
.page-inside h4 {
  margin: 48px 0px 24px 0px;
  font-size: 1.6rem;
  color: rgb(33, 43, 54);
  font-weight: 700;
}
.page-inside ul {
  font-size: 1.2rem;
  line-height: 2rem;
  list-style: none;
}
.page-inside ul li {
  margin: 0px 0px 16px 0px;
  padding: 0px 0px 0px 42px;
  position: relative;
  text-align: justify;
}
.page-inside ul li::before {
  content: "";
  position: absolute;
  background: url(../images/check-bullet-round.svg) left top no-repeat;
  left: 0px;
  top: 6px;
  width: 20px;
  height: 20px;
}
.page-inside ol {
  font-size: 1.2rem;
  line-height: 2rem;
  padding: 0px 0px 0px 32px;
}
.page-inside ol li {
  margin: 0px 0px 16px 0px;
  padding: 0px 0px 0px 12px;
  position: relative;
  text-align: justify;
}
.page-inside ol li::marker {
  color: rgba(0, 167, 111);
}
.page-features ul li::before {
  display: none;
}
.page-features .home-features {
  background: none;
}
.page-features .home-features .feature-box {
  width: 100%;
}
.page-features .mobile-screen {
  border-radius: 8px;
  box-shadow: rgba(145, 158, 171, 0.2) 0px 6px 7px -4px,
    rgba(145, 158, 171, 0.14) 0px 11px 15px 1px,
    rgba(145, 158, 171, 0.12) 0px 4px 20px 3px;
}
.page-features .home-features .feature-box {
  min-height: 152px;
}
.page-features .home-features .feature-box h5 {
  font-size: 0.8rem;
  line-height: 18px;
}
.page-features .home-features .features-icon {
  font-size: 48px;
}
.page-features .home-features .features-icon img {
  height: 48px !important;
}
.page-features .home-features {
  padding: 24px 0px;
}
.gdpr-popup {
  background: #fff;
  position: fixed;
  left: 28px;
  bottom: 42px;
  width: 412px;
  box-shadow: rgba(145, 158, 171, 0.2) 0px 7px 8px -4px,
    rgba(145, 158, 171, 0.14) 0px 13px 19px 2px,
    rgba(145, 158, 171, 0.12) 0px 5px 24px 4px;
  border-radius: 8px;
  z-index: 99999;
}

.page-contact .contact-details {
  background: linear-gradient(135deg, rgb(0, 167, 111), rgb(0, 120, 103));
  border-radius: 8px;
  padding: 24px;
  box-shadow: rgba(145, 158, 171, 0.2) 0px 7px 8px -4px,
    rgba(145, 158, 171, 0.14) 0px 13px 19px 2px,
    rgba(145, 158, 171, 0.12) 0px 5px 24px 4px;
}
.page-contact .contact-details h5 {
  border-bottom: solid 1px rgba(256, 256, 256, 0.5);
  padding-bottom: 16px;
  margin-bottom: 16px;
  font-size: 1.4rem;
}

.page-contact .contact-email {
  display: flex;
  align-items: center;
}
.page-contact .contact-email a {
  text-decoration: none;
  color: #fff;
  font-size: 1.2rem;
  font-weight: 600;
}
.page-contact .contact-email a:hover {
  text-decoration: underline;
}
.page-contact .contact-call {
  display: flex;
  text-decoration: none;
  color: #fff;
  font-size: 1.1rem;
  align-items: center;
  font-weight: 600;
}
.page-contact .material-symbols-outlined {
  height: 42px;
  width: 42px;
  background: rgba(256, 256, 256, 0.9);
  border-radius: 50%;
  color: rgba(0, 167, 111);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  line-height: 24px;
  margin-right: 16px;
}
.page-contact .sm-icons img {
  height: 42px;
  margin-right: 16px;
}
.contact-form {
  box-shadow: rgba(145, 158, 171, 0.2) 0px 7px 8px -4px,
    rgba(145, 158, 171, 0.14) 0px 13px 19px 2px,
    rgba(145, 158, 171, 0.12) 0px 5px 24px 4px;
  border-radius: 8px;
  padding: 42px;
}
.error-red {
  color: red;
  font-size: 0.9rem !important;
}
.page-about h2 {
  font-size: 3rem;
}
.about-services span {
  background: rgb(0, 167, 111);
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 28px;
  color: #fff;
  border-radius: 50%;
  margin-right: 16px;
}
.about-services span.c1 {
  background: rgb(0, 167, 111);
}
.about-services span.c2 {
  background: #346e9c;
}
.about-services span.c3 {
  background: #ff9933;
}
.about-services span.c4 {
  background: rgb(221, 95, 101);
}
.page-about .about-team-advisory {
  padding: 0px;
  margin: 0px;
  line-height: 1.8rem;
}
.page-about .about-team-advisory li {
  margin: 0px;
  font-size: 0.9rem;
}
.downloads-popup-bg {
  position: fixed;
  left: 0px;
  top: 0px;
  z-index: 99999999;
  width: 100%;
  height: 100vh;
  background: rgba(9, 16, 29, 0.5);
  -webkit-animation: animatebg 0.6s;
  animation: animatebg 0.6s;
}
.downloads-popup {
  background: #fff;
  width: 54%;
  height: fit-content;
  position: fixed;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  margin: auto auto;
  z-index: 10000;
  border-radius: 24px;
  padding: 24px;
  box-shadow: rgba(145, 158, 171, 0.2) 0px 10px 14px -6px,
    rgba(145, 158, 171, 0.14) 0px 22px 35px 3px,
    rgba(145, 158, 171, 0.12) 0px 8px 42px 7px;
  -webkit-animation: animatezoom 0.6s;
  animation: animatezoom 0.6s;
}
.downloads-popup-close {
  position: absolute;
  right: 8px;
  top: 8px;
}
.downloads-popup-close span {
  font-weight: bolder;
  color: rgba(33, 43, 54, 0.8);
}
.downloads-popup .home-features {
  padding: 0px;
  background: none;
}
.downloads-popup .home-features .feature-box {
  min-height: 72px;
  cursor: default;
}
.downloads-popup .home-features .features-icon {
  font-size: 36px;
}
.downloads-popup .home-features .features-icon img {
  width: 36px;
  margin-top: -16px;
}
.downloads-popup .home-features .feature-box h5 {
  font-size: 0.9rem;
  text-align: left;
  margin: 0px 0px 0px 12px;
}
.downloads-popup input.form-control {
  border: solid 1px rgba(0, 167, 111);
}

.lang-btn {
  position: fixed;
  right: 20px;
  bottom: 20px;
  z-index: 10000000;
  background: rgb(0, 167, 111);
  border: 0px none;
  color: #fff;
  margin: 0px;
  border-radius: 50%;
  width: 48px;
  height: 48px;
  box-shadow: rgba(145, 158, 171, 0.2) 0px 7px 8px -4px,
    rgba(145, 158, 171, 0.14) 0px 12px 17px 2px,
    rgba(145, 158, 171, 0.12) 0px 5px 22px 4px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
}

@keyframes bgScroll1 {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 0 10000%;
  }
}
@keyframes bgScroll2 {
  0% {
    background-position: 0 10000%;
  }
  100% {
    background-position: 0 0;
  }
}
@keyframes animatezoom {
  0% {
    -webkit-transform: scale(0);
  }
  100% {
    -webkit-transform: scale(100%);
  }
}
@keyframes animatebg {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media (max-width: 575px) {
  .mobile-hide {
    display: none !important;
  }
  header .navbar {
    display: none;
  }
  header img {
    margin: 16px 0px;
  }
  .home h2 {
    font-size: 2.4rem;
  }
  .home-slider {
    height: auto !important;
    padding: 112px 0px 48px 0px;
  }

  .home-slider-height {
    height: auto !important;
  }
  .home-slider-content {
    min-height: 272px;
  }
  .home-slider h3 {
    font-size: 1.6rem;
  }
  .home-slider h2 {
    line-height: 3.2rem;
  }
  .home-slider .btn img {
    width: 50%;
  }
  .banner-curve-l,
  .banner-curve-r1,
  .banner-curve-r2,
  .banner-curve-r3 {
    display: none !important;
  }
  .home-features {
    padding: 24px 0px;
  }
  .home-features-icons {
    display: none;
  }
  .home-features-icons-mobile {
    display: block !important;
  }
  .home-features-icons-mobile-wrap {
    display: flex;
    overflow-x: scroll;
    padding: 8px 0px 24px 0px;
  }
  .home-features .feature-box {
    min-height: 128px !important;
    min-width: 128px !important;
    width: auto !important;
    justify-content: center;
    margin-right: 16px;
  }
  .home-features .features-icon {
    font-size: 42px;
  }
  .home-features .features-icon img {
    height: 42px !important;
    margin-top: -28px;
  }
  .home-features .feature-box h5 {
    font-size: 0.9rem;
  }
  .home-features h2 {
    margin: 0px 0px 24px 0px !important;
    text-align: center;
  }
  .home-features h2::after {
    right: 0px;
  }
  .home-features-content {
    padding-left: 0px;
    padding-right: 16px;
  }
  .home-features-content h3 {
    margin-bottom: 24px !important;
    text-align: center;
  }
  .home-features-content .btn {
    margin: 0px auto;
  }
  .home-features-content ul li {
    font-size: 1.1rem;
    margin-bottom: 12px;
    text-align: justify;
  }

  .home-howitworks {
    padding: 24px 0px 0px 0px;
  }
  .home-howitworks h5 {
    font-size: 1.2rem;
    margin-bottom: 0px !important;
  }
  .home-howitworks h3 {
    font-size: 1.2rem;
    margin-top: 24px;
  }
  .home-howitworks ul {
    padding-left: 4px;
  }
  .home-howitworks .mobile-screen {
    display: none;
  }

  .home-benefits {
    padding: 24px;
  }
  .home-benefits h5 {
    font-size: 1.2rem;
    margin-bottom: 16px !important;
  }

  .home-whoweare {
    padding: 24px 0px 0px 0px;
  }
  .home-whoweare .letter-box {
    text-align: center;
  }
  .home-whoweare .letter {
    font-size: 92px;
    line-height: 92px;
  }
  .home-testimonials {
    padding: 24px 0px 24px 0px;
  }
  .home-testimonials h5 {
    font-size: 1.2rem;
    margin-bottom: 24px !important;
  }
  .home-blogs {
    padding: 24px 0px 24px 0px;
  }
  .home-blogs h5 {
    font-size: 1.2rem;
    margin-bottom: 16px !important;
  }
  .home-blogs .btn {
    margin-top: 16px !important;
  }

  .home-benefits-img {
    display: none;
  }
  .home-benefits {
    padding: 24px 0px 0px 0px;
  }
  .home-benefits h5 {
    font-size: 1.2rem;
  }
  .home-benefits h4 {
    width: 100%;
    text-align: center;
    margin-top: 24px;
    padding-top: 32px;
    margin-bottom: 24px;
    font-size: 1.2rem;
    padding-bottom: 16px;
  }
  .home-benefits ul,
  .home-benefits ul li {
    width: 100%;
    position: relative;
  }
  .home-benefits span {
    width: 48px;
    height: 48px;
    font-size: 28px;
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    margin: 0px auto !important;
  }
  .home-benefits span::after {
    height: 300% !important;
    width: 2px !important;
    margin: 0px auto !important;
    left: 0px !important;
    right: 0px !important;
  }
  .home-benefits ul li:last-child span::after {
    display: none;
  }
  .home-benefits ul,
  .home-benefits ul li {
    justify-content: flex-start !important;
  }
  .home-benefits ul span {
    order: 1;
  }
  .home-benefits ul h4 {
    order: 2;
  }

  footer .footer-c > div {
    border: 0px none;
    margin-top: 8px;
    padding-top: 16px;
    padding-bottom: 16px;
    border-top: solid 1px rgba(42, 143, 109, 0.3);
    border-bottom: solid 1px rgba(42, 143, 109, 0.3);
  }
  .footer-r {
    margin-top: 24px;
  }

  .page-inside-banner {
    min-height: 128px;
    margin-bottom: 28px;
    margin-top: 72px;
  }
  .page-inside h1 {
    font-size: 2.2rem;
  }
  .page-inside p {
    font-size: 1rem;
    line-height: 1.6rem;
  }
  .page-inside ul li,
  .page-inside ol li {
    font-size: 1rem;
    line-height: 1.6rem;
  }
  .page-inside h4 {
    font-size: 1.4rem;
    margin: 28px 0px 16px 0px;
  }
  .page-inside ul {
    margin: 0px;
    padding: 0px;
  }
  .page-about h2 {
    font-size: 2rem;
    font-weight: bold;
  }
  .page-about .btn.btn-lg.btn-primary {
    margin: 42px 0px;
  }
  .page-contact .contact-form {
    padding: 24px;
    margin-bottom: 24px;
  }
  .page-contact .download-buttons img {
    width: 100%;
  }
  .page-features .home-features-content {
    padding: 0px !important;
  }
  .gdpr-popup {
    width: 82%;
    margin: 0px auto;
    left: 0px;
    right: 0px;
  }
  .downloads-popup {
    width: 94%;
  }
  .downloads-popup .form-control {
    margin-bottom: 24px;
  }
  .home-features-mobile-download {
    display: none;
  }
  .mobile-menu-btn {
    padding: 0px;
    margin: 0px;
    border: none;
    width: 42px;
    height: 42px;
    display: flex !important;
    align-items: center;
    justify-content: center;
    background: none;
    position: absolute;
    right: 16px;
    top: 16px;
  }
  .mobile-menu-btn span {
    font-size: 32px;
    padding: 0px;
    margin: 0px;
    line-height: normal;
  }
  .show-mobile-menu {
    display: block !important;
    -webkit-animation: animatemenu 0.5s;
    animation: animatemenu 0.5s;
  }

  header .navbar,
  header .navbar::before {
    width: 100%;
    position: fixed;
    right: 0px;
    background: rgb(0, 167, 111);
    top: 72px;
    height: calc(100vh - 72px);
    padding: 0px;
    margin: 0px;
    z-index: 1;
  }
  .navbar > ul {
    flex-wrap: wrap;
    padding: 24px 42px !important;
  }
  .navbar ul li {
    width: 100%;
  }
  .navbar ul li a {
    color: #fff !important;
    padding: 16px 0px;
    margin: 0px;
    border-bottom: solid 1px rgba(256, 256, 256, 0.5);
    line-height: 28px;
  }
  .navbar ul li a .material-symbols-outlined {
    color: #fff !important;
  }
  .navbar ul li .btn {
    border: solid 2px #fff !important;
    margin-top: 16px;
    display: inline-block;
  }
}

@keyframes animatemenu {
  0% {
    left: 100%;
  }
  100% {
    left: 0px;
  }
}
